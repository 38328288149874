import React from 'react';

const isMobile = window.innerWidth < 768 ? true : false;
const windowHeight = window.innerHeight;

const responsiveValue = {
    isMobile,
    windowHeight
}

export const ResponsiveContext = React.createContext(responsiveValue);