import React from "react"
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody } from "@chakra-ui/react"
import { FaTimes, FaMobileAlt, FaCarSide, FaPhoneAlt } from "react-icons/fa"
import "./order-modal.css"

const OrderModal = ({ open, onClose }) => {
    return (
        <Modal isOpen={open} onClose={onClose}>
            <ModalOverlay />
            <ModalContent borderRadius={0} mx={4} pb={4} className="modal">
                <ModalHeader className="modal-header">
                    <div>Order now</div>
                    <FaTimes className="close-icon" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <a href="https://order.southerncoopwings.com/s/order" target="_blank" rel="noopener noreferrer">
                        <div className="button">
                            <FaMobileAlt className="button-icon" />
                            Online order
                        </div>
                    </a>
                    <a
                        href="https://www.fetchtdelivery.com/r/709/restaurants/delivery/Fried-Chicken/Southern-Coop-Oxford"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className="button">
                            <FaCarSide className="button-icon" />
                            Delivery order
                        </div>
                    </a>
                    <a href="tel:+16626383767">
                        <div className="button">
                            <FaPhoneAlt className="button-icon" />
                            Phone order
                        </div>
                    </a>
                    <div className="helper">* Online and phone orders are for pickup only</div>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default OrderModal
