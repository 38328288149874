import React from "react"
import { Link } from "react-router-dom"
import posed from "react-pose"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "../style/app-subheader.css"

const SubHeaderBanner = posed.div({
    visible: {
        width: "auto",
        opacity: 1,
        transition: { duration: 200, delay: 500, ease: "easeInOut" },
    },
    hidden: {
        width: 0,
        opacity: 0,
        transition: { duration: 200 },
    },
})

const SubHeaderBannerText = posed.div({
    visible: {
        opacity: 1,
        transition: { duration: 100, delay: 700, ease: "easeInOut" },
    },
    hidden: {
        opacity: 0,
        transition: { duration: 100 },
    },
})

const SubHeaderButton = posed.div({
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 300, delay: 1000, ease: "easeInOut" },
    },
    hidden: {
        y: 10,
        opacity: 0,
        transition: { duration: 300 },
    },
})

const AppSubHeader = ({ text, buttonText, buttonIcon, buttonUrl, internal }) => (
    <div className="subheader">
        <SubHeaderBanner className="subheader-text" initialPose="hidden" pose="visible">
            <SubHeaderBannerText>{text}</SubHeaderBannerText>
        </SubHeaderBanner>
        {!internal && (
            <a href={buttonUrl} target="_blank" rel="noopener noreferrer">
                <SubHeaderButton className="subheader-button button" initialPose="hidden" pose="visible">
                    <FontAwesomeIcon className="subheader-button-icon" icon={buttonIcon}></FontAwesomeIcon>
                    {buttonText}
                </SubHeaderButton>
            </a>
        )}
        {internal && (
            <Link to={buttonUrl}>
                <SubHeaderButton className="subheader-button button" initialPose="hidden" pose="visible">
                    <FontAwesomeIcon className="subheader-button-icon" icon={buttonIcon}></FontAwesomeIcon>
                    {buttonText}
                </SubHeaderButton>
            </Link>
        )}
    </div>
)

export default AppSubHeader
