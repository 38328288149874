import React from "react"
import "../style/menu-item.css"

const MenuItem = ({ name, price, desc, imageSrc }) => {
    return (
        <div className="menu-item">
            {imageSrc && <img src={imageSrc} alt={name} />}
            <div className="menu-item-details">
                <div className="first-row">
                    <div className="name">{name}</div>
                    <div className="price">{isNaN(price) ? price : `$${price.toFixed(2)}`}</div>
                </div>
                {desc && <div className="description">{desc}</div>}
            </div>
        </div>
    )
}

export default MenuItem
