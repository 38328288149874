import React from "react"

const Signature = () => {
    return (
        <a href="https://github.com/tamntn" target="_blank" rel="noopener noreferrer">
            Website by tamntn
        </a>
    )
}

export default Signature
