import React, { Component } from "react"
import { ChakraProvider } from "@chakra-ui/react"
import { BrowserRouter, Route, Switch } from "react-router-dom"

import Home from "./components/Home"
import Menu from "./components/Menu"
import Catering from "./components/Catering"
import Contact from "./components/Contact"
import Hirings from "./components/Hirings"
import NotFound from "./components/404"
import { ResponsiveContext } from "./components/context/ResponsiveContext"
import { theme } from "./config/theme"

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMobile: window.innerWidth < 768 ? true : false,
            windowHeight: window.innerHeight,
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateResponsiveValue)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateResponsiveValue)
    }

    updateResponsiveValue = () => {
        this.setState({
            isMobile: window.innerWidth < 768 ? true : false,
            windowHeight: window.innerHeight,
        })
    }

    render() {
        const responsiveValue = {
            isMobile: this.state.isMobile,
            windowHeight: this.state.windowHeight,
        }

        return (
            <ChakraProvider resetCSS={false} theme={theme}>
                <ResponsiveContext.Provider value={responsiveValue}>
                    <BrowserRouter>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/menu" component={Menu} />
                            <Route exact path="/catering" component={Catering} />
                            <Route exact path="/contact" component={Contact} />
                            <Route exact path="/hirings" component={Hirings} />
                            <Route component={NotFound} />
                        </Switch>
                    </BrowserRouter>
                </ResponsiveContext.Provider>
            </ChakraProvider>
        )
    }
}

export default App
