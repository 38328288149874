export const menuOptions = {
    Appetizers: "Appetizers",
    Salads: "Salads",
    Wings: "Wings",
    Tenders: "Tenders",
    Sandwiches: "Sandwiches",
    Sides: "Sides",
    Drinks: "Drinks",
    "Kids Meals": "Kids Meals",
}

export const menuImageUrls = {
    "Loaded Potato Skins": "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/01.+Loaded+Potato+Skins+(2)-min.jpg",
    "Jalapeno Baby Wrap": "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/02.+Jalapeno+Baby+Wrap-min.jpg",
    "Garlic Romano Ranch": "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/03.+Garlic+Romano+Ranch+(1)-min.jpg",
    "Fried Okra": "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/04.+Fried+Okra-min.jpg",
    "Bacon & Cheddar": "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/05.+Bacon+%26+Cheddar+(1)-min.jpg",
    Buffalo: "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/06.+Buffalo+(1)-min.jpg",
    "BBQ Chicken Nachos": "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/07.+BBQ+Chicken+Nachos-min.jpg",
    "Honey Gold": "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/08.+Honey+Gold+(2)-min.jpg",
    "Western Ranch Fries": "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/09.+Western+Ranch+Fries-min.jpg",
    Quesadilla: "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/10.+Chicken+Bacon+Ranch+Quesadilla-min.jpg",
    "Smokey & The Bandit": "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/11.+Smokey+%26+The+Bandit-min.jpg",
    "Chicken Tenders": "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/12.+Chicken+Tenders-min.jpg",
    "Lemon Pepper": "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/13.+Lemon+Pepper+(1)-min.jpg",
    "Big Texas": "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/14.+Big+Texas-min.jpg",
    "BBQ Bacon Cheddar Baby": "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/15.+BBQ+Bacon+Cheddar+Baby-min.jpg",
    "Chicken Salad": "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/16.+Chicken+Salad-min.jpg",
}
