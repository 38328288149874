import React, { useEffect } from "react"
import ReactGA from "react-ga"
import posed, { PoseGroup } from "react-pose"
import { Link } from "react-router-dom"
import { useDisclosure } from "@chakra-ui/react"
import { ParallaxProvider, ParallaxBanner, Parallax } from "react-scroll-parallax"

import Banner from "./Banner"
import AppHeader from "./AppHeader"
import AppFooter from "./AppFooter"
import AboutImage from "../images/about-2.jpg"
import VarietyImage from "../images/variety-1.jpg"
import QualityImage from "../images/quality-1.jpg"
import OrderModal from "./shared/order-modal"
import "../style/home.css"

const LandingBackgroundImage = posed.div({
    visible: {
        transition: { duration: 350, delay: 500, ease: "easeInOut" },
        scale: 1.0,
    },
    hidden: {
        transition: { duration: 350 },
        scale: 1.15,
    },
})

const LandingBanner = posed.div({
    visible: {
        width: "auto",
        opacity: 1,
        transition: { duration: 250, delay: 850, ease: "easeInOut" },
    },
    hidden: {
        width: 0,
        opacity: 0,
        transition: { duration: 250 },
    },
})

const LandingBannerText = posed.div({
    visible: {
        opacity: 1,
        transition: { duration: 250, delay: 1250, ease: "easeInOut" },
    },
    hidden: {
        opacity: 0,
        transition: { duration: 250 },
    },
})

const LandingActions = posed.ul({
    visible: {
        delayChildren: 1400,
        staggerChildren: 100,
    },
    hidden: {},
})

const LandingActionsButton = posed.li({
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 200 },
    },
    hidden: {
        y: 20,
        opacity: 0,
        transition: { duration: 200 },
    },
})

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        ReactGA.initialize("UA-147225916-1")
        ReactGA.pageview(window.location.pathname)
    }, [])

    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <ParallaxProvider>
            <AppHeader delay />
            <ParallaxBanner
                className="landing"
                layers={[
                    {
                        children: (
                            <LandingBackgroundImage initialPose="hidden" pose="visible" className="landing-background"></LandingBackgroundImage>
                        ),
                        amount: 0.4,
                        expanded: false,
                    },
                ]}
                style={{
                    height: "100vh",
                }}
            >
                <LandingBanner className="landing-banner" initialPose="hidden" pose="visible">
                    <LandingBannerText>
                        Best wings in town,
                        <span className="next-line">
                            <br />
                        </span>{" "}
                        and more!
                    </LandingBannerText>
                </LandingBanner>
                <LandingActions className="landing-actions" initialPose="hidden" pose="visible">
                    <PoseGroup>
                        <div key={0} onClick={onOpen}>
                            <LandingActionsButton className="landing-actions-button button">Order</LandingActionsButton>
                        </div>
                        <Link to="/menu" key={1}>
                            <LandingActionsButton className="landing-actions-button button">Menu</LandingActionsButton>
                        </Link>
                        <Link to="/catering" key={2}>
                            <LandingActionsButton className="landing-actions-button button">Catering</LandingActionsButton>
                        </Link>
                        <Link to="/contact" key={3}>
                            <LandingActionsButton className="landing-actions-button button">Contact</LandingActionsButton>
                        </Link>
                    </PoseGroup>
                </LandingActions>
            </ParallaxBanner>
            <div className="home">
                <Banner />
                {/* ABOUT */}
                <div className="home-section">
                    <div className="home-section-left">
                        <Parallax y={[15, -10]}>
                            <div className="home-section-content">
                                <div className="subtitle">WE'RE OPEN!</div>
                                <div className="title">Oxford, MS</div>
                                <div className="info">
                                    Southern Coop is a locally owned and operated restaurant in Oxford, MS. We know you will love our signature
                                    chicken wings, house made chicken tenders, and sandwiches!
                                    <br />
                                    <br />
                                    Our mission at Southern Coop is to provide our customers with impeccable service all while serving you a delicious
                                    meal. We are honored that you have chosen to visit with us!
                                </div>
                            </div>
                        </Parallax>
                    </div>
                    <div className="home-section-right">
                        <Parallax y={[-15, 10]}>
                            <img src={AboutImage} alt="about"></img>
                        </Parallax>
                    </div>
                </div>
                {/* MENU VARIETY */}
                <div className="home-section">
                    <div className="home-section-left">
                        <Parallax x={[-10, 0]}>
                            <img src={VarietyImage} alt="variety"></img>
                        </Parallax>
                    </div>
                    <div className="home-section-right">
                        <Parallax x={[10, 0]}>
                            <div className="home-section-content">
                                <div className="subtitle">VARIETY</div>
                                <div className="title">50+ Wing Flavors</div>
                                <div className="info">
                                    From our signature Buffalo sauce to the ever popular Honey Gold, we have the flavors to satisfy everyone's
                                    cravings.
                                </div>
                            </div>
                        </Parallax>
                    </div>
                </div>
                {/* QUALITY */}
                <div className="home-section">
                    <div className="home-section-left">
                        <Parallax y={[15, -10]}>
                            <div className="home-section-content">
                                <div className="subtitle">QUALITY</div>
                                <div className="title">Fresh & Local</div>
                                <div className="info">
                                    Using fresh and locally sourced ingredients, we pride ourselves in making outstanding chicken entrees. Our mission
                                    at Southern Coop is to provide our customers with impeccable service all while serving you a delicious meal.
                                </div>
                            </div>
                        </Parallax>
                    </div>
                    <div className="home-section-right">
                        <Parallax y={[-15, 10]}>
                            <img src={QualityImage} alt="quality"></img>
                        </Parallax>
                    </div>
                </div>
            </div>
            <AppFooter />
            <OrderModal open={isOpen} onClose={onClose} />
        </ParallaxProvider>
    )
}

export default Home
