import React, { Component } from "react"
import posed from "react-pose"
import { Link } from "react-router-dom"
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMobileAlt, faCarSide, faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import { faFacebookF, faTwitter, faInstagram, faGoogle, faYelp } from "@fortawesome/free-brands-svg-icons"

import { ResponsiveContext } from "./context/ResponsiveContext"
import "../style/app-menu.css"

import Signature from "./shared/signature"

const AppMenuWrapper = posed.div({
    visible: {
        opacity: 1,
        transition: { duration: 250, ease: "easeInOut" },
    },
    hidden: {
        opacity: 0.5,
        transition: { duration: 250 },
    },
})

class AppMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.appMenuRef = React.createRef()
    }

    componentWillUnmount() {
        clearAllBodyScrollLocks()
    }

    componentDidUpdate() {
        if (this.props.open) {
            disableBodyScroll(this.appMenuRef)
        } else {
            enableBodyScroll(this.appMenuRef)
        }
    }

    render() {
        const { open, closeMenu } = this.props
        const { windowHeight } = this.context
        const year = new Date().getFullYear()

        return (
            <AppMenuWrapper
                ref={this.appMenuRef}
                className="app-menu"
                style={{ height: windowHeight, visibility: open ? "visible" : "hidden" }}
                pose={open ? "visible" : "hidden"}
            >
                <div className="app-menu-content" style={{ display: open ? "flex" : "none" }}>
                    <Link to="/">
                        <div className="app-menu-link" onClick={closeMenu}>
                            Home
                        </div>
                    </Link>
                    <Link to="/menu">
                        <div className="app-menu-link" onClick={closeMenu}>
                            Menu
                        </div>
                    </Link>
                    <Link to="/catering">
                        <div className="app-menu-link" onClick={closeMenu}>
                            Catering
                        </div>
                    </Link>
                    <Link to="/contact">
                        <div className="app-menu-link" onClick={closeMenu}>
                            Contact
                        </div>
                    </Link>
                    <Link to="/hirings">
                        <div className="app-menu-link" onClick={closeMenu}>
                            Hirings
                        </div>
                    </Link>
                    <div className="app-menu-actions">
                        <a href="https://order.southerncoopwings.com/s/order" target="_blank" rel="noopener noreferrer">
                            <div className="app-menu-button button">
                                <FontAwesomeIcon icon={faMobileAlt} className="app-menu-button-icon" />
                                Online order
                            </div>
                        </a>
                        <a
                            href="https://www.fetchtdelivery.com/r/709/restaurants/delivery/Fried-Chicken/Southern-Coop-Oxford"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="app-menu-button button">
                                <FontAwesomeIcon icon={faCarSide} className="app-menu-button-icon" />
                                Delivery order
                            </div>
                        </a>
                        <a href="tel:+16626383767">
                            <div className="app-menu-button button">
                                <FontAwesomeIcon icon={faPhoneAlt} className="app-menu-button-icon" />
                                Phone order
                            </div>
                        </a>
                        {/* <a
                            href="https://www.google.com/maps/dir//Southern+Coop,+1120+N+Lamar+Blvd,+Oxford,+MS+38655/@34.3783029,-89.5306476,14z/data=!4m9!4m8!1m0!1m5!1m1!1s0x88807ba64197676f:0xbf8d7632db112e29!2m2!1d-89.5108649!2d34.3770263!3e0"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="app-menu-button button">
                                <FontAwesomeIcon icon={faDirections} className="app-menu-button-icon" />
                                Get directions
                            </div>
                        </a> */}
                    </div>
                    <div className="app-menu-socials">
                        <a href="https://www.facebook.com/southerncoopwings/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebookF} className="app-menu-social-icon"></FontAwesomeIcon>
                        </a>
                        <a href="https://twitter.com/CoopSouthern" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faTwitter} className="app-menu-social-icon"></FontAwesomeIcon>
                        </a>
                        <a href="https://www.instagram.com/southerncoopwings/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} className="app-menu-social-icon"></FontAwesomeIcon>
                        </a>
                        <a href="https://goo.gl/maps/F6X7cwG9VQYM7QM29" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faGoogle} className="app-menu-social-icon"></FontAwesomeIcon>
                        </a>
                        <a href="https://www.yelp.com/biz/southern-coop-oxford" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faYelp} className="app-menu-social-icon"></FontAwesomeIcon>
                        </a>
                    </div>
                    <div className="app-menu-signature">
                        <div>{`© ${year} Southern Coop Wings`}</div>
                        <Signature />
                    </div>
                </div>
            </AppMenuWrapper>
        )
    }
}

AppMenu.contextType = ResponsiveContext

export default AppMenu
