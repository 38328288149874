import React, { useEffect } from "react"
import ReactGA from "react-ga"
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons"

import AppHeader from "./AppHeader"
import AppSubHeader from "./AppSubHeader"
import AppFooter from "./AppFooter"
import HiringsImage from "../images/hirings.jpg"
import "../style/contact.css"

const Hirings = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        ReactGA.initialize("UA-147225916-1")
        ReactGA.pageview(window.location.pathname)
    }, [])

    return (
        <div>
            <AppHeader delay={false} />
            <AppSubHeader internal text="Hirings" buttonText="Back to Home" buttonIcon={faLongArrowAltLeft} buttonUrl={"/"} />
            <div className="contact">
                <div className="contact-info">
                    <div className="contact-info-box">
                        <div className="contact-info-box-title">Phone number</div>
                        <div className="contact-info-box-content">(662) 638-3767</div>
                        <div className="contact-info-box-content">Call us to find out about open positions!</div>
                    </div>
                </div>
                <div className="contact-image">
                    <img src={HiringsImage} alt="hirings" />
                </div>
            </div>
            <AppFooter />
        </div>
    )
}

export default Hirings
