import { extendTheme } from "@chakra-ui/react"

const styles = {
    global: {
        body: {
            backgroundColor: "#00a6da",
        },
    },
}

export const theme = extendTheme({ styles })
