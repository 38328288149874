import React, { useEffect } from "react"
import ReactGA from "react-ga"
import { Link } from "react-router-dom"
import posed from "react-pose"
import AppHeader from "./AppHeader"
import "../style/app-404.css"

const NotFoundBanner = posed.div({
    visible: {
        width: "auto",
        opacity: 1,
        transition: { duration: 200, delay: 500, ease: "easeInOut" },
    },
    hidden: {
        width: 0,
        opacity: 0,
        transition: { duration: 200 },
    },
})

const NotFoundBannerText = posed.div({
    visible: {
        opacity: 1,
        transition: { duration: 100, delay: 700, ease: "easeInOut" },
    },
    hidden: {
        opacity: 0,
        transition: { duration: 100 },
    },
})

const NotFoundButton = posed.div({
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 300, delay: 1000, ease: "easeInOut" },
    },
    hidden: {
        y: 10,
        opacity: 0,
        transition: { duration: 300 },
    },
})

const NotFound = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        ReactGA.initialize("UA-147225916-1")
        ReactGA.pageview("/404-not-found")
    }, [])

    return (
        <div>
            <AppHeader delay={false} />
            <div className="not-found" style={{ height: window.innerHeight }}>
                <NotFoundBanner className="banner" initialPose="hidden" pose="visible">
                    <NotFoundBannerText initialPose="hidden" pose="visible">
                        404 Page Not Found{" "}
                        <span role="img" aria-label="not-found-emoji">
                            🙅🏻‍♂️ 🐓
                        </span>
                    </NotFoundBannerText>
                </NotFoundBanner>
                <Link to="/">
                    <NotFoundButton className="return-button button" initialPose="hidden" pose="visible">
                        Return Home
                    </NotFoundButton>
                </Link>
            </div>
        </div>
    )
}

export default NotFound
