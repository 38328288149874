import React, { useState, useEffect } from "react"
import ReactGA from "react-ga"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload, faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons"

import AppHeader from "./AppHeader"
import AppSubHeader from "./AppSubHeader"
import AppFooter from "./AppFooter"
import MenuItem from "./MenuItem"
import WingSauces from "./shared/wing-sauces"
import CateringFile from "../menu/southern-coop-catering.pdf"

const cateringOptions = {
    Wings: "Wings",
    Tenders: "Tenders",
    Salads: "Salads",
    "Sandwiches & Wraps": "Sandwiches & Wraps",
    "Box Lunches": "Box Lunches",
    Drinks: "Drinks",
    "Set-Up": "Set-Up",
}

const Catering = () => {
    const [option, setOption] = useState(cateringOptions.Wings)

    useEffect(() => {
        window.scrollTo(0, 0)
        ReactGA.initialize("UA-147225916-1")
        ReactGA.pageview(window.location.pathname)
    }, [])

    return (
        <div>
            <AppHeader delay={false} />
            <AppSubHeader text="Our Catering" buttonText="View Catering PDF" buttonIcon={faDownload} buttonUrl={CateringFile} />
            <div className="menu-options">
                <div className="menu-options-scroll-indicator-left">
                    <FontAwesomeIcon icon={faCaretLeft} />
                </div>
                <div className="menu-options-scrollable">
                    {Object.keys(cateringOptions).map((key) => {
                        return (
                            <div
                                key={key}
                                className={`menu-option button ${option === cateringOptions[key] ? "selected" : ""}`}
                                onClick={() => setOption(cateringOptions[key])}
                            >
                                {cateringOptions[key]}
                            </div>
                        )
                    })}
                </div>
                <div className="menu-options-scroll-indicator-right">
                    <FontAwesomeIcon icon={faCaretRight} />
                </div>
            </div>
            <div className="menu">
                <div className="menu-selected-option">{option}</div>
                {option === cateringOptions.Wings && (
                    <React.Fragment>
                        <WingSauces />
                        <div className="wing-type">
                            <div className="wing-type-name">Bone-In WINGS</div>
                            <div className="wing-prices-wrapper">
                                <div>
                                    <div className="size">75 PIECES</div>
                                    <div className="price">$79.99</div>
                                </div>
                                <div>
                                    <div className="size">100 PIECES</div>
                                    <div className="price">$104.99</div>
                                </div>
                            </div>
                        </div>
                        <div className="wing-type">
                            <div className="wing-type-name">BONELESS WINGS</div>
                            <div className="wing-prices-wrapper">
                                <div>
                                    <div className="size">75 PIECES</div>
                                    <div className="price">$55.99</div>
                                </div>
                                <div>
                                    <div className="size">100 PIECES</div>
                                    <div className="price">$74.99</div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                {option === cateringOptions.Tenders && (
                    <React.Fragment>
                        <div className="addon">
                            <div className="addon-name">Dipping Sauces</div>
                            <div className="addon-option">Coop sauce</div>
                            <div className="addon-option">Scratch-made ranch</div>
                            <div className="addon-option">Blue cheese</div>
                            <div className="addon-option">Honey mustard</div>
                            <div className="addon-option">Marinara</div>
                            <div className="addon-option">BBQ</div>
                        </div>
                        <MenuItem name="12 Pieces" price={18.99} />
                        <MenuItem name="25 Pieces" price={39.99} />
                        <MenuItem name="50 Pieces" price={74.99} />
                        <MenuItem name="75 Pieces" price={104.99} />
                    </React.Fragment>
                )}
                {option === cateringOptions.Salads && (
                    <React.Fragment>
                        <div className="addon">
                            <div className="addon-name">Dressings</div>
                            <div className="addon-option">Scratch-made Ranch</div>
                            <div className="addon-option">Lite Ranch</div>
                            <div className="addon-option">Thousand Island</div>
                            <div className="addon-option">Blue Cheese</div>
                            <div className="addon-option">Honey Mustard</div>
                            <div className="addon-option">Italian</div>
                            <div className="addon-option">Caesar</div>
                        </div>
                        <div className="catering-salad-item">
                            <div className="catering-salad-name">House Salad</div>
                            <div className="catering-salad-desc">
                                Mixed greens, topped with cheddar & monterey jack cheese, bacon, cucumbers, cherry tomatoes and eggs
                            </div>
                            <div className="catering-salad-row">
                                <div>Half pan (feeds 5-10)</div>
                                <div>$21.99</div>
                            </div>
                            <div className="catering-salad-row">
                                <div>Full pan (feeds 20-30)</div>
                                <div>$42.99</div>
                            </div>
                        </div>
                        <div className="catering-salad-item">
                            <div className="catering-salad-name">Chicken Salad</div>
                            <div className="catering-salad-desc">Our house salad topped with your choice of grilled or fried chicken</div>
                            <div className="catering-salad-row">
                                <div>Half pan (feeds 5-10)</div>
                                <div>$29.99</div>
                            </div>
                            <div className="catering-salad-row">
                                <div>Full pan (feeds 20-30)</div>
                                <div>$60.49</div>
                            </div>
                        </div>
                        <div className="catering-salad-item">
                            <div className="catering-salad-name">Loaded Baked Potato Salad</div>
                            <div className="catering-salad-desc">Our fresh homemade potato salad loaded with crispy bacon & cheddar cheese</div>
                            <div className="catering-salad-row">
                                <div>Half pan (feeds 5-10)</div>
                                <div>$45.99</div>
                            </div>
                            <div className="catering-salad-row">
                                <div>Full pan (feeds 20-30)</div>
                                <div>$85.99</div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                {option === cateringOptions["Sandwiches & Wraps"] && (
                    <React.Fragment>
                        <div className="menu-selected-option-description">
                            Sandwiches are made with our delicious homemade fried or grilled tenders on an 8" hoagie, dressed as noted
                        </div>
                        <div className="catering-sandwiches">
                            <div className="catering-sandwiches-name">Tender Melt</div>
                            <div className="catering-sandwiches-desc">Mozzarella and choice of sauce</div>
                            <div className="catering-sandwiches-name">The Bacon Cheddar Baby</div>
                            <div className="catering-sandwiches-desc">Bacon & cheddar cheese</div>
                            <div className="catering-sandwiches-name">Mr. Italy</div>
                            <div className="catering-sandwiches-desc">
                                Dipped in garlic butter, garlic pepper, romano, mozzarella - with a side of marinara
                            </div>
                            <div className="catering-sandwiches-name">Chicken BLT</div>
                            <div className="catering-sandwiches-desc">Bacon, lettuce, tomato and mayo</div>
                            <div className="catering-sandwiches-name">Chicken Wrap</div>
                            <div className="catering-sandwiches-desc">Grilled or fried chicken, cheese, bacon, lettuce, tomato & choice of sauce</div>
                            <div className="catering-sandwiches-name">Just a Chick</div>
                            <div className="catering-sandwiches-desc">Lettuce, tomato, cheese & honey mustard</div>
                            <div className="catering-sandwiches-name">Smokey and The Bandit</div>
                            <div className="catering-sandwiches-desc">Smoked sausage, cheese, lettuce & tomato</div>
                        </div>
                        <div>
                            <MenuItem name="Small Platter" price={47.49} desc="Serves 5 - 10 people" />
                            <MenuItem name="Medium Platter" price={91.99} desc="Serves 15 - 20 people" />
                            <MenuItem name="Large Platter" price={139.99} desc="Serves 25 - 30 people" />
                        </div>
                    </React.Fragment>
                )}
                {option === cateringOptions["Box Lunches"] && (
                    <React.Fragment>
                        <div className="menu-selected-option-description">
                            Choose from our delicious sandwiches. All lunches include a sandwich and chips.
                        </div>
                        <MenuItem name="Per Person" price={6.49} desc="8 - 10 person minimum" />
                    </React.Fragment>
                )}
                {option === cateringOptions.Drinks && (
                    <React.Fragment>
                        <MenuItem name="Per Gallon" price={4.99} desc="Lemonade, Fruit Punch, Sweet Tea & Unsweet Tea" />
                    </React.Fragment>
                )}
                {option === cateringOptions["Set-Up"] && (
                    <React.Fragment>
                        <div className="menu-selected-option-description">Includes plates, cups, lids, straws, utensils, napkins & ice</div>
                        <MenuItem name="Up to 15 People" price={9.99} />
                        <MenuItem name="Up to 50 People" price={19.99} />
                    </React.Fragment>
                )}
            </div>
            <AppFooter />
        </div>
    )
}

export default Catering
