import React, { Component } from "react"
import posed from "react-pose"
import { Link } from "react-router-dom"
import { FaShoppingCart } from "react-icons/fa"

import AppMenu from "./AppMenu"
import NameLogo from "../images/name-logo-transparent.png"
import NameLogoWhite from "../images/name-logo-white-1.png"
import OrderModal from "./shared/order-modal"
import "../style/app-header.css"

class AppHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openMenu: false,
            openModal: false,
        }
        this.AppHeaderWrapper = posed.div({
            visible: {
                opacity: 1,
                transition: { duration: 500, delay: this.props.delay ? 2000 : 500, ease: "easeInOut" },
            },
            hidden: {
                opacity: 0,
                transition: { duration: 500 },
            },
        })
    }

    toggleMenu = () => {
        this.setState({
            openMenu: !this.state.openMenu,
        })
    }

    closeMenu = () => {
        this.setState({
            openMenu: false,
        })
    }

    openModal = () => {
        this.setState({
            openModal: true,
        })
    }

    closeModal = () => {
        this.setState({
            openModal: false,
        })
    }

    render() {
        const { openMenu, openModal } = this.state

        return (
            <React.Fragment>
                <this.AppHeaderWrapper className="header" initialPose="hidden" pose="visible">
                    <div className="header-left">
                        <Link to="/">
                            <img className={openMenu ? "hidden" : "visible"} src={NameLogo} alt="name-logo"></img>
                        </Link>
                        <img className={openMenu ? "visible" : "hidden"} src={NameLogoWhite} alt="name-logo"></img>
                    </div>
                    <div className="header-right">
                        <div className="header-call button" style={{ display: openMenu ? "none" : "flex" }} onClick={this.openModal}>
                            <FaShoppingCart className="header-icon" />
                            <div>Order</div>
                        </div>
                        <div className="header-menu button" onClick={this.toggleMenu}>
                            <div className={`hamburger hamburger--slider ${openMenu ? "is-active" : ""}`} onClick={this.toggleMobileMenu}>
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </this.AppHeaderWrapper>
                <AppMenu open={openMenu} closeMenu={this.closeMenu} />
                <OrderModal open={openModal} onClose={this.closeModal} />
            </React.Fragment>
        )
    }
}

export default AppHeader
