import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/free-solid-svg-icons"

const WingSauces = () => (
    <div className="addon">
        <div className="addon-name">Wing Sauces</div>
        <div className="addon-option-grid">
            <div className="addon-option">Hot</div>
            <div className="addon-option">Medium</div>
            <div className="addon-option">Mild</div>
            <div className="addon-option">Honey BBQ</div>
            <div className="addon-option">Golden BBQ</div>
            <div className="addon-option">Cajun BBQ</div>
            <div className="addon-option">Honey Cajun BBQ</div>
            <div className="addon-option">Honey Hot</div>
            <div className="addon-option">Honey Cajun</div>
            <div className="addon-option">Honey Mustard</div>
            <div className="addon-option">Hot Honey Mustard</div>
            <div className="addon-option">
                Honey Gold <FontAwesomeIcon icon={faHeart} className="addon-option-icon" />
            </div>
            <div className="addon-option">Hot Honey Gold</div>
            <div className="addon-option">Cajun Honey Gold</div>
            <div className="addon-option">Jamaican Jerk</div>
            <div className="addon-option">Jalapeno</div>
            <div className="addon-option">XX Hot Pepper</div>
            <div className="addon-option">Garlic Parmesan</div>
            <div className="addon-option">Ranch</div>
            <div className="addon-option">Cajun Ranch</div>
            <div className="addon-option">Buffalo Ranch</div>
            <div className="addon-option">Garlic Romano Ranch</div>
            <div className="addon-option">Bacon Cheddar Ranch (+$1.99/dozen)</div>
            <div className="addon-option">Bacon & Cheddar (+$1.99/dozen)</div>
            <div className="addon-option">Spicy Garlic Cajun</div>
            <div className="addon-option">Alfredo</div>
            <div className="addon-option">Cajun Alfredo</div>
            <div className="addon-option">Teriyaki</div>
            <div className="addon-option">Pineapple Teriyaki</div>
            <div className="addon-option">Tiger</div>
            <div className="addon-option">Sweet & Sour</div>
            <div className="addon-option">Hotty Toddy</div>
            <div className="addon-option">Korean Pepper</div>
            <div className="addon-option">Kicking Bourbon</div>
            <div className="addon-option">Sweet & Salty</div>
            <div className="addon-option">Mango Habanero</div>
            <div className="addon-option">Plum</div>
            <div className="addon-option">Taco</div>
            <div className="addon-option">General Tso</div>
            <div className="addon-option">Oxford Hot</div>
            <div className="addon-option">Citrus Chipotle</div>
            <div className="addon-option">Shark</div>
            <div className="addon-option">All American</div>
            <div className="addon-option">Sriracha</div>
            {/* Dry wings */}
            <div></div>
            <div className="addon-option dry">DRY SEASONINGS</div>
            <div className="addon-option">Lemon Pepper</div>
            <div className="addon-option">Garlic Pepper</div>
            <div className="addon-option">Buffalo</div>
            <div className="addon-option">Caribbean Jerk</div>
            <div className="addon-option">BBQ</div>
            <div className="addon-option">Cajun</div>
            <div className="addon-option">Taco</div>
            <div className="addon-option">Ranch</div>
        </div>
    </div>
)

export default WingSauces
